import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  IconButton,
  useDisclosure,
  Link as ChakraLink,
  Stack,
  Container,
  Text,
  CloseButton,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { hasCookie, setCookie } from 'cookies-next';
import 'instantsearch.css/themes/reset.css';
import 'instantsearch.css/themes/satellite.css';
import dynamic from 'next/dynamic';
import { HeaderMenuData } from '@/Types/types';
import { configFile } from '@/config';
import Search from '@/components/MainPage/Search';

interface Props {
  menu: HeaderMenuData[];
  cookies: any;
}

function HeaderMenu({ menu, cookies }: Props) {
  const { isOpen, onToggle } = useDisclosure();
  const [isCookieBanner, seIsCookieBanner] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<Boolean>();
  const MobileNav = dynamic(() => import('@/components/MobileNav'), {
    ssr: false,
  });

  useEffect(() => {
    if (!hasCookie('notFirstVisit')) {
      seIsCookieBanner(true);
    }

    setIsMobile(window.innerWidth <= 830);

    const cookiesData = async () => {
      /* eslint-disable */
      cookies.map((item: any) => {
        if (!hasCookie(`${item.attributes.name}`)) {
          const maxAge = () => {
            if (
              item.attributes.duration.days * item.attributes.duration.hours * item.attributes.duration.minutes ===
              0
            ) {
              return new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
            }

            return item.attributes.duration.days * item.attributes.duration.hours * item.attributes.duration.minutes;
          };

          setCookie(`${item.attributes.name}`, `${item.attributes.description}`, { path: '/', maxAge: maxAge(), sameSite: 'strict', });
        }
      });
    };

    cookiesData();
  }, []);

  const closeCookieBanner = () => {
    seIsCookieBanner(false);
    setCookie('notFirstVisit', true, {
      path: '/',
      maxAge: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime(),
    });
  };

  return (
    <>
      {isCookieBanner && (
        <Container as="section" pb="10px" zIndex="999" position="fixed" bottom="0" minWidth="100%">
          <Box
            bg={configFile.primaryAccentColor}
            color="#fff"
            p={{ base: '4', md: '3' }}
            py={{ base: '3', md: '5' }}
            position="relative"
            borderRadius="xl"
            margin="0 auto"
            width="90vw"
            maxWidth="1216px"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify="center"
              spacing={{ base: '0.5', md: '1.5' }}
              pe={{ base: '4', sm: '0' }}
            >
              <Text color="#fff" fontWeight="medium">
                This site is using cookies.
              </Text>
              <Text color="#fff">
                Read our{' '}
                <ChakraLink as={Link} href="/privacy-notice">
                  Privacy notice
                </ChakraLink>
              </Text>
            </Stack>
            <CloseButton onClick={closeCookieBanner} position="absolute" right="2" top={{ base: '2', md: '4' }} />
          </Box>
        </Container>
      )}
      <Box className="nav-wrapper" zIndex="999" position="fixed" backgroundColor="#fff" top="0px" width="100%">
        <Flex
          maxW="1216px"
          width="95%"
          m="0 auto"
          pt={{ base: '15px', lg: '28px' }}
          pb={{ base: '15px', lg: '28px' }}
          align="center"
          justifyContent="space-between"
        >
          <Flex align="center" columnGap="84px">
            <Link href="/">
              <Image src='/images/logo.svg' alt="Ecto" width="95" height="28" priority placeholder="blur" blurDataURL='/images/logo.svg' />
            </Link>
            <Box display={{ base: 'none', lg: 'block' }}>
              <Search />
            </Box>
          </Flex>
          {menu && (
            <>
              <Flex align="center" columnGap="32px" as="nav" display={{ base: 'none', lg: 'flex' }}>
                {menu.map((item: HeaderMenuData) => (
                  <ChakraLink
                    href={item.path}
                    key={item.id}
                    fontWeight={600}
                    color="#1A202C"
                    _hover={{ color: configFile.secondaryAccentColor }}
                    _last={{
                      bgColor: configFile.primaryAccentColor,
                      color: '#fff',
                      borderRadius: '8px',
                      pt: '8px',
                      pb: '8px',
                      pr: '16px',
                      pl: '16px',
                      _hover: { background: configFile.secondaryAccentColor },
                    }}
                    as={Link}
                  >
                    {item.title}
                  </ChakraLink>
                ))}
              </Flex>
              {isMobile && <MobileNav isOpen={isOpen} item={menu} />}
            </>
          )}
          <IconButton
            onClick={onToggle}
            display={{ lg: 'none' }}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
      </Box>
    </>
  );
}

export default HeaderMenu;
